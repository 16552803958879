<template>
  <div>
    <h1>Отчеты</h1>
    <h4 ><div style="text-align: center;margin-top: 0;margin-bottom: 0;">Уважаемый пользователь!</div>
    <div style="text-indent: 10px;margin-top: 0;margin-bottom: 0">Обращаем ваше внимание: в соотв. с Постановлением Минприроды №5-Т (от 01.02.2024 г.) 26.04.2024 вступили в силу изменения ЭкоНиП 17.01.06-001-2017 «Охрана окружающей среды и природопользование. Требования экологической безопасности» в части ведения учета отходов. </div>
    <div style="text-indent: 10px;margin-top: 0;margin-bottom: 0">- Скорректирована форма ПОД-9 (графа 9).</div>
    <div style="text-indent: 10px;margin-top: 0;margin-bottom: 0">- В соотв. с п. 108 абз. 3 "в формах ПОД-9 и ПОД-10 не отражается информация, касаемая отходов, отношения с которыми не регулируются законодательством об обращении с отходами". </div>
    <div style="text-indent: 10px;margin-top: 0;margin-bottom: 0">В случае необходимости внесения изменений в личный кабинет пользователя просим обратиться в службу технической поддержки.</div>
    </h4>
    

    <el-divider ></el-divider>
    <el-row :gutter="10" v-if="user_id!=503">
      <el-col :span="20">
        <h3>ПОД-9 <h5>(в соотв. с ЭкоНиП 17.01.06-001-2017 (в ред. пост. № 5-Т от 01.02.2024 г.))</h5></h3>
      </el-col>

      <el-col :span="4">
        <el-button @click="pod('pod-9-2023')" type="primary">Создать</el-button>
      </el-col>
    </el-row>
    <el-divider v-if="user_id!=503"></el-divider>
    <el-row :gutter="10" v-if="user_id!=503">
      <el-col :span="20">
        <h3>ПОД-10 <h5>(в соотв. с ЭкоНиП 17.01.06-001-2017 (в ред. пост. № 5-Т от 01.02.2024 г.))</h5></h3>
      </el-col>

      <el-col :span="4">
        <el-button @click="pod('pod-10-2023')" type="primary">Создать</el-button>
      </el-col>
    </el-row>
    <el-divider v-if="user_id==245"></el-divider>


    <el-row :gutter="10" v-if="user_id==245 || user_id==409">
      <el-col :span="20">
        <h3>ПОД-9 <h5>(в соотв. с ТКП 17.02-12-2014 (02120))</h5></h3>
      </el-col>

      <el-col :span="4">
        <el-button @click="pod('pod-9')" type="primary">Создать</el-button>
      </el-col>
    </el-row>
    <el-divider v-if="user_id==245 || user_id==409"></el-divider>
    <el-row :gutter="10" v-if="user_id==245 || user_id==409">
      <el-col :span="20">
        <h3>ПОД-10 <h5>(в соотв. с ТКП 17.02-12-2014 (02120))</h5></h3>
      </el-col>

      <el-col :span="4">
        <el-button @click="pod('pod-10')" type="primary">Создать</el-button>
      </el-col>
    </el-row>
    <el-divider v-if="user_id!=503"></el-divider>


    <el-row :gutter="10">
      <el-col :span="20">
        <h3>Журнал регистрации сопроводительных паспортов</h3>
      </el-col>

      <el-col :span="4">
        <el-button @click="logpassport" type="primary">Создать</el-button>
      </el-col>
    </el-row>
    <el-divider></el-divider>

    <el-row :gutter="10">
      <el-col :span="20">
        <h3>Сопроводительные паспорта перевозки отходов</h3>
      </el-col>

      <el-col :span="4">
        <el-button @click="passport" type="primary">Создать</el-button>
      </el-col>
    </el-row>
    <el-divider></el-divider>


    <el-row :gutter="10" v-if="user_id!=503">
      <el-col :span="20">
        <h3>Внутренний отчет</h3>
      </el-col>

      <el-col :span="4">
        <el-button @click="MainReport" type="primary">Создать</el-button>
      </el-col>
    </el-row>
    <el-divider v-if="user_id!=503"></el-divider>

    <el-row :gutter="10" v-if="user_id!=503">
      <el-col :span="20">
        <h3>Госстатотчетность 1-отходы (Минприроды)</h3>
      </el-col>

      <el-col :span="4">
        <el-button @click="waste1" type="primary">
          Создать</el-button>
      </el-col>
    </el-row>
    
    <el-divider v-if="user_id==559"></el-divider>

    <el-row :gutter="10" v-if="user_id==559">
      <el-col :span="20">
        <h3>Справка для расчета экологического налога</h3>
      </el-col>

      <el-col :span="4">
        <el-button @click="reportCreacte('referenceren')" type="primary">
          Создать</el-button>
      </el-col>
    </el-row>
    <el-divider v-if="user_id!=503"></el-divider>

    <el-row :gutter="10" v-if="user_id==14 ||
user_id==25 ||
user_id==124">
      <el-col :span="20">
        <h3>Экологический паспорт предприятия</h3>
      </el-col>

      <el-col :span="4">
        <el-button @click="epp" type="primary">Создать</el-button>
      </el-col>
    </el-row>
    <el-divider v-if="user_id!=503"></el-divider>

    <el-row :gutter="10" v-if="user_id!=503">
      <el-col :span="20">
        <h3>Акт инвентаризации</h3>
      </el-col>

      <el-col :span="4">
        <el-button type="primary">Создать</el-button>
      </el-col>
    </el-row>
    <el-divider v-if="user_id!=503"></el-divider>
  </div>
</template>

<script>
import Cookies from "js-cookie";
export default {
  name: "reportsMain",

  data() {
    return {
        user_id: 0
    };
  },

  methods: {
    pod(reportname){
      this.$router.push(reportname);
    },

    passport() {
      this.$router.push("passport");
    },

    MainReport() {
      this.$router.push("MainReport");
    },

    logpassport() {
      console.log('passs');
      this.$router.push("logpassport");
    },
    reportCreacte(name){
      this.$router.push(name);
    },


    waste1() {
      this.$router.push("waste-1");
    },

    epp() {
      this.$router.push("epp");
    },
  },

  mounted() {
    this.user_id= Cookies.get('userId');
  },
};
</script>

<style scoped></style>
